// Font style
/*
    Extra-light 200
    Light       300
    Regular     400
    Semi-bold   600
    Bold        700
    Extra-bold  800
    Black       900
*/
$font-family: 'Poppins',  sans-serif;
$font-family-chivo: 'Quicksand', sans-serif;
$font-family-playfair: 'Quicksand', serif;
$font-style-extra-light : 200;
$font-style-light       : 300;
$font-style-regular     : 400;
$font-style-semi-bold   : 600;
$font-style-bold        : 700;
$font-style-extra-bold  : 800;
$font-style-black       : 900;

.text-weight-bold{
    font-weight: $font-style-bold !important;
}
.text-weight-extra-light{
    font-weight: $font-style-extra-light !important;
}
.text-weight-light{
    font-weight: $font-style-light !important;
}
.text-weight-regular{
    font-weight: $font-style-regular !important;
}
.text-weight-black{
    font-weight: $font-style-black !important;
}
.text-weight-extra-bold{
    font-weight: $font-style-extra-bold !important;
}
.text-weight-semi-bold {
    font-weight: $font-style-semi-bold !important;
}

.row {
  --bs-gutter-y: 1.5rem;
  margin-top: initial;
}

.input-group-text {
  padding: 0.75rem 0.75rem;
  border-radius: 0;
}

// color pallete
$black       : #000;
$white       : #FFF;

$brand-color : #195174;
$green       : #007794;
$green3      : #009D9F;
$green4      : #2EC295;
$green5      : #96E17F;
$yellow      : #F7C30C;
$red         : #FE453D;
$light-red   : #FFEDED;

$light-green : #29D972;
$blue        : #009CF8;

$orange      : #F78E00;


$light-grey  : #202124;
$light-grey2 : #FAFAFA;
$grey        : #C4C4C4;
$grey2       : #E7EAEE;
$dark-grey   : #000;

$disabled-bg-color        : #F5F5F5;
$disabled-color           : #999;
$form-border-color        : #CCC;
$form-separator-color     : #E7EAEE;
$form-placeholder-color   : #999;
$form-error-color         : #FF0000;
$form-label-color         : #000;
$form-helper-color        : #000;

// max width for body - content
$max-width-wrapper: 100%;
$max-width-dialog : 660px;

// z-index
$z-index-form: 4;
$z-index-fixed-button: 100;

@mixin ramerame-bg-gradient($important: false ){
    @if $important{
        background: linear-gradient(90deg, #195174 0%, #009D9F 100%) !important;
    }
    @if not $important{
        background: linear-gradient(90deg, #195174 0%, #009D9F 100%) ;

    }
}

@mixin ramerame-box-shadow(){
    -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,0.24);
    -moz-box-shadow: 0 0 20px 0 rgba(0,0,0,0.24);
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.24);
}


.bg-brand{
    background-color: $brand-color;
}

.bg-red-smooth {
  background-color: #f78e00 !important;
}

.bg-brand-gradient{
    @include ramerame-bg-gradient();
}

.text-grey{
    color: $dark-grey;

    &:before{
        color: $dark-grey;
    }
}

.text-orange{
    color: $orange;
    &:before{
        color: $orange;
    }
}

.text-yellow{
    color: $yellow;
    &:before{
        color: $yellow;
    }
}

.text-red, .text-danger{
    color: $red;
    &:before{
        color: $red;
    }
}

.text-green {
  color: $green3;
  &:before{
    color: $green3;
  }
}

.brand-color, .text-brand-color{
    color: $brand-color;

    &:before{
        color: $brand-color;
    }
}
