.well{
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    border-radius: 6px;
    border:1px solid $grey2;
    background: $light-grey2;

    &.red{
        background: $light-red;
        border-color: $light-red;
        color: $form-label-color;
    }

    &.grey2{
        background: $grey2;
    }

    &.no-border{
        border: none;
    }
}