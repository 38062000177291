@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?n946k0');
  src:  url('fonts/icomoon.eot?n946k0#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?n946k0') format('truetype'),
    url('fonts/icomoon.woff?n946k0') format('woff'),
    url('fonts/icomoon.svg?n946k0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clarity_minus-circle-solid:before {
  content: "\e930";
  color: #9fadbd;
}
.icon-ic_round-view-list:before {
  content: "\e92f";
  color: #009d9f;
}
.icon-eye1:before {
  content: "\e931";
}
.icon-eye-blocked1:before {
  content: "\e932";
}
.icon-poin:before {
  content: "\e92c";
  color: #f78e00;
}
.icon-history:before {
  content: "\e92d";
  color: #9fadbd;
}
.icon-awards:before {
  content: "\e92e";
  color: #195174;
}
.icon-timeline:before {
  content: "\e92b";
  color: #195174;
}
.icon-filter:before {
  content: "\e92a";
  color: #9fadbd;
}
.icon-withdraw:before {
  content: "\e926";
  color: #195174;
}
.icon-ask-admin:before {
  content: "\e927";
  color: #195174;
}
.icon-cctv:before {
  content: "\e928";
  color: #195174;
}
.icon-deposit:before {
  content: "\e929";
  color: #195174;
}
.icon-close:before {
  content: "\e924";
  color: #9fadbd;
}
.icon-chevron-right:before {
  content: "\e925";
  color: #9fadbd;
}
.icon-minus:before {
  content: "\e922";
  color: #9fadbd;
}
.icon-plus:before {
  content: "\e901";
  color: #195174;
}
.icon-trash:before {
  content: "\e923";
  color: #9fadbd;
}
.icon-logout:before {
  content: "\e919";
}
.icon-ticket:before {
  content: "\e91a";
}
.icon-security:before {
  content: "\e91b";
}
.icon-card:before {
  content: "\e91c";
}
.icon-person:before {
  content: "\e91d";
}
.icon-identitas:before {
  content: "\e91e";
}
.icon-exclamation:before {
  content: "\e91f";
  color: #9fadbd;
}
.icon-menu:before {
  content: "\e920";
  color: #fff;
}
.icon-search:before {
  content: "\e918";
  color: #ccc;
}
.icon-phone:before {
  content: "\e917";
  color: #007794;
}
.icon-tag:before {
  content: "\e912";
  color: #9fadbd;
}
.icon-jumlah-slot:before {
  content: "\e913";
  color: #9fadbd;
}
.icon-investor:before {
  content: "\e914";
  color: #9fadbd;
}
.icon-date:before {
  content: "\e915";
  color: #9fadbd;
}
.icon-information:before {
  content: "\e916";
  color: #9fadbd;
}
.icon-twitter:before {
  content: "\e90b";
  color: #007794;
}
.icon-instagram:before {
  content: "\e90c";
  color: #007794;
}
.icon-facebook:before {
  content: "\e90d";
  color: #007794;
}
.icon-mail:before {
  content: "\e90e";
  color: #007794;
}
.icon-pin-map:before {
  content: "\e90f";
  color: #007794;
}
.icon-mail-send:before {
  content: "\e910";
  color: #fff;
}
.icon-arrow-back:before {
  content: "\e911";
  color: #fff;
}
.icon-transparan:before {
  content: "\e908";
  color: #195174;
}
.icon-aman:before {
  content: "\e909";
  color: #195174;
}
.icon-jempol:before {
  content: "\e90a";
  color: #195174;
}
.icon-usaha-saya:before {
  content: "\e900";
  color: #ccc;
}
.icon-akun:before {
  content: "\e902";
  color: #ccc;
}
.icon-back:before {
  content: "\e903";
  color: #fff;
}
.icon-cart:before {
  content: "\e904";
  color: #fff;
}
.icon-dompet:before {
  content: "\e905";
  color: #ccc;
}
.icon-home:before {
  content: "\e906";
  color: #195174;
}
.icon-laporan:before {
  content: "\e907";
  color: #c4c4c4;
}
.icon-notification:before {
  content: "\e921";
  color: #fff;
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-download3:before {
  content: "\e9c7";
}
.icon-eye-blocked:before {
  content: "\e9d1";
}
.icon-bookmarks:before {
  content: "\e9d3";
}
.icon-file-pdf:before {
  content: "\eadf";
}
