.fixActionRow {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.spacer {
  flex-grow: 1;
}
.mat-dialog-container{
  background: $white;
  color: $black;
  max-height: initial;

  max-width: $max-width-dialog;
  .close-button{
    position: relative;
    text-align: right;
    top: -20px;
    margin-top: 15px;
    button{
      min-width: auto;
      padding: 0;
      font-size: 24px;
      &:hover, &:active{
        .icon-close:before{
          color: $brand-color;
        }
      }
    }
  }

  .top-image{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    img{
      max-width: 80%;
      @include media-breakpoint-up(md){
        max-width: 55%;
      }
    }
  }

  .mat-dialog-actions{
    margin-bottom: auto;
    min-height: unset;
  }

  .mat-dialog-content{
    max-height: initial !important;
  }

  .mat-h5, .mat-typography h5 {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-style: normal;
  }
}

.cdk-overlay-pane {
  max-width: 100% !important;
}

.md-dialog {
  
  height: 400px !important;
  width: 600px !important;
  border-radius: 10px;
  background: lightcyan;
  color: #039be5;
}
