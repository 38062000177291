.contact-us{
    [class^="icon-"]{
        font-size: 24px;
        flex-basis: 30px;
    }
    a [class^="icon-"]{
        &:hover{
            &:before{
                color: $green3;
            }
        }
    }
}