.homepage{

    .heading{
        max-width: 80%;
        text-align: center;
        margin: auto;
    }

    .sliding-card{
        overflow: auto;
        flex-wrap: nowrap;
    }

    .three-icon{
        [class^="icon-"], [class*=" icon-"] {
            min-width: 60px;
            height: 60px;
            // background: #E7EAEE;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 25px;
            margin-right: 1.5rem;
            margin-bottom: 4rem;
        }
    }
    .homeslider1{
        .owl-dots{
            position: absolute;
            z-index: 10;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);

            .owl-dot{
                span{
                    background: $white;
                    // opacity: 0.4;
                }
                &.active{
                    span{
                        opacity: 1;
                    }
                }
            }
        }
        .owl-item{
            .slider-content-wrapper{
                border-radius: 6px;
                &.bg-brand-color{
                    background-color: $brand-color;
                }
                background: none;
              padding: 3.5rem 3.5rem 8rem;
              @include media-breakpoint-up(md){
                padding: 11rem 3.5rem;
              }

              display: flex;
                .bottom-right{
                    // position: relative;
                    // bottom: -1.5rem;
                    // right: -1.5rem;
                    // opacity: 0.4;
                    position: absolute;
                    bottom: 0;
                    right: 0;

                }
            }
        }
    }

    .phone{
        .frame{
            width: 300px;
            height: auto;
            position: absolute;
            z-index: 10;
            left: 50%;
            transform: translateX(-50%);
        }

        .iphone-frame{
            max-width: 300px;
            height: 600px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            .top{
                width: 300px;
                height: 41px;
                background: url(/assets/images/landing/top.png) no-repeat;
                background-size: cover;
                position: relative;
                z-index: 12;
            }
            .bottom{

                width: 303px;
                height: 44px;
                background: url(/assets/images/landing/bottom.png) no-repeat;
                position: absolute;
                background-size: cover;
                z-index: 14;
                bottom: 0;
                img{
                    width: 300px;
                }
            }

            // .top::before{
            //     width: 300px;
            //     height: 50px;
            //     top: 0;
            //     left:0;
            //     position: absolute;
            //     z-index: 13;
            //     content: '';
            // }

            // .bottom::before{
            //     content: '';
            //     width: 300px;
            //     position: absolute;
            //     height: 40px;
            //     top: 0;
            //     z-index: 13;

            // }
            .middle{
                position: relative;
                &::before{
                    content: '';
                    width: 24px;
                    height: 520px;
                    z-index: 13;
                    position: absolute;
                    background: url(/assets/images/landing/left.png) no-repeat;
                    background-size: cover;
                    left: 0;
                    top: 0;
                }
                &::after{
                    content: '';
                    width: 22px;
                    height: 520px;
                    z-index: 13;
                    position: absolute;
                    background: url(/assets/images/landing/right.png) no-repeat;
                    background-size: cover;
                    right: 0;
                    top: 0;
                }
                img{
                    height: 514px;
                    position: relative;
                    z-index: 12;

                    &:last-child{
                        float: right;
                    }
                }
            }
            .content{
                z-index: 11;
                position: absolute;
                width: 285px;
                height: 580px;
                overflow: auto;
                padding-top: 20px;
                left: 50%;
                transform: translateX(-50%);
                top: 0;

                &.no-scroll{
                    overflow: hidden;
                }
            }
        }
        // .content{
        //     z-index: 9;
        //     position: relative;
        //     width: 285px;
        //     height: 580px;
        //     overflow: auto;
        //     padding-top: 20px;
        //     left: 50%;
        //     transform: translateX(-50%);
        // }
        .owl-carousel, .owl-carousel.owl-loaded {

            .item{
            }

            .owl-stage{
            }
        }
    }

    .how-to-slider{
        .phone-image{
            max-width: 200px;
            margin: auto;
        }
        .informative-box{
            background: linear-gradient(180deg, #195174 0%, #009D9F 100%);
            display: flex;
            border-radius: 12px;
            padding: 1rem;
            .number{
                min-width: 40px;
                height: 40px;
                margin-right: 10px;
                display:flex;
                align-items: center;
                justify-content: center;
                font-weight: $font-style-bold;
                background: $white;
                border-radius: 50%;
            }
            .description{
                color: $white;
            }
        }
    }

    .testimonial-section{
        background: $light-grey;
        margin-left: -15px;
        margin-right: -15px;

        .card{
            border: none;
            .box-shadow{
                box-shadow: 0 5px 12px rgba(0, 0, 0, 0.06);
                padding-top: 30px;
                padding-bottom: 30px;
            }
            max-width: 95%;
            margin: auto;
            border-radius: 12px;
        }

        .testimony-content{
            max-width: 80%;
            margin: auto;
        }

        .testimony-image{
            max-width: 100px;
            max-height: 100px;
            border-radius: 50%;
            margin: auto;
            display: block;
        }
    }
}
