  .mat-expansion-panel{
    background: $white;
    box-shadow: none !important;

    .mat-expansion-panel-header{
      height: auto;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .mat-expansion-panel-header-title,
    .mat-expansion-panel-body,
    .header-title{
      color: $black;
    }
    border: 1px solid $grey2;
    border-top: none;

    .mat-expansion-panel-header:hover{
      background: $white !important;
    }
  }

  // mat datepicker button
  .mat-datepicker-toggle{
    .mat-icon-button{
      margin-top: -1px;
      border: 1px solid $grey;
      background-color: #e9ecef;
      border-radius: 0;
      height: 38px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      .mat-datepicker-toggle-default-icon{
        path{
          fill: $brand-color;
        }
      }
    }
  }

  // mat checkbox
  .mat-checkbox-inner-container{
    border: 1px solid $form-border-color;
  }

  .mat-checkbox-checked .mat-checkbox-inner-container{
    border-color: $brand-color;
  }

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background{
    border-color: $brand-color;
    background: $brand-color;
    border-radius: 0;
  }

  .mat-checkbox-checked .mat-checkbox-checkmark-path{
    stroke: $white !important;
  }

  // mat radio
  .mat-radio-button{
    margin-right: 0.5rem;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
    border-color: $green3;
    background: $green3;
  }

  .mat-radio-outer-circle{
    border-color: $form-border-color;
  }
  .mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{
    border-color: $white;
    background: $white;
  }

  // ng-select
.ng-select-container{
  height: 38px;
  font-family: $font-family;
  font-size: 1rem;
  input[type="text"]{

      font-family: $font-family;
      font-size: 1rem;
      &::placeholder{
          font-family: $font-family;
          font-size: 1rem;
      }
  }

  .ng-dropdown-panel-items{
      .ng-option{
          font-family: $font-family;
          font-size: 1rem;
      }
  }
}


// mat-tabs
  .mat-tab-group{
    .mat-tab-header{
      background-color: #fff;
      .mat-tab-labels{
        div{
          width: 50%;
        }
      }
    }
    .mat-tab-body-wrapper{
      background-color: #FAFAFA;
      padding: 10px;
    }
  }
  .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $brand-color;
  }
  .mat-tab-label-content{
    color: $brand-color;
    font-weight: bold;
  }
  .mat-tab-group .mat-tab-body-wrapper{
    background-color: transparent;
  }
