.form-group{
    label{
        font-weight: $font-style-bold;
        font-size: 14px;
        line-height: 24px;
        color: $form-label-color;
    }
    .radio-group{
        display:flex;
        flex-direction: column;
    }
    .form-control{
        border: 1px solid $grey;
        background: $white;

        outline-color: $brand-color;
        font-family: $font-family;

        &::placeholder{
            color: $form-placeholder-color !important;
            font-family: $font-family !important;
        }

        &:disabled{
            background-color: $disabled-bg-color;
            color: $disabled-color;
        }
    }

    .form-text{
        font-family: $font-family;
        color: $form-helper-color;
        margin-top: 10px;

    }
    &.has-error{
        input, select{
            border-color: $form-error-color;
        }

        .form-text{
            color: $form-error-color;
        }
    }

    // dropzone for ktp upload
    .dropzone{
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        border: 2px dashed $grey;
        background: $white;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .helper{
        font-weight: $font-style-regular;
        ul{
            margin: 0;
            padding-left: 20px;
        }
    }
}

.filter-panel{
    display:flex;
    align-items:center;
    justify-content: space-between;
    &.scrollable{
        overflow: auto;
        white-space: nowrap;
        display: block;
        flex-wrap: nowrap;
        .filter{

        }
    }
}

.input-group{
    &.has-button-append{
        .form-control{
            border-radius: 6px;
        }
        .input-group-append{
            border: none;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
        }
    }
}

.pagination{
    justify-content: center;
    .page-item{
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        a, button{
            font-size: 16px;
            line-height: 21px;
            font-weight: $font-style-regular;
            color: $form-label-color;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        &.active{
            a, button{
                background: $brand-color;
                color: $white;
                border-color: $brand-color;
            }
        }
    }
}

.grey-separator{
    height: 10px;
    background: $light-grey;
    width: calc(100% + 32px);
    position: relative;
    left: -16px;
    right: -16px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.form-separator{
    border-top: 1px solid $form-separator-color;
    width: 100%;
    min-height: 1px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .middle-text{
        position: relative;
        background: $white;
        padding-left: 15px;
        padding-right: 15px;
        top: -10px;
    }
}

.table{
  &.fixed{
    table-layout: fixed;
  }
  tbody {
    td {
      vertical-align: middle;
    }
  }
}
