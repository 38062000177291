.sign-up {
  .logo {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 20px;
    margin: auto;
  }

  .logo img {
    width: 173px;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 24px 24px;
    position: relative;
    width: 327px;
    margin: auto;
    top: 75px;
    z-index: 11;
    background: #FFFFFF;
    box-shadow: 0 24px 32px rgba(0, 119, 148, 0.45);
    border-radius: 12px;
  }

  .quote {
    position: relative;
    width: 343px;
    height: 42px;
    margin: auto;
    top: 70px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
  }

  .box h2 {
    position: static;
    width: 279px;
    height: 25px;
    left: 24px;
    top: 32px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #195174;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0;
  }

  .box form {
    width: 100%;
    margin: 24px;
  }

  .form-group label, .form-group input {
    font-size: 14px;
  }

  .form-group input {
    padding: 12px;
  }

  .form-group input:focus {
    outline: none;
    border-color: #ccc;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .form-group label {
    color: #195174;
  }

  #input-group-password input, #input-group-password-confirmation input{
    border-right: none;
  }

  #input-group-password .input-group-text, #input-group-password-confirmation .input-group-text {
    background: transparent;
  }

  .submit-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    position: static;
    width: 279px;
    height: 51px;
    left: 24px;
    top: 268px;
    background: linear-gradient(88.73deg, #195174 0%, #009D9F 100%);
    box-shadow: 0 6px 6px rgba(36, 72, 97, 0.12);
    border-radius: 6px;
    color: #fff;
    border:none;
    flex: none;
    order: 4;
    align-self: stretch;
    flex-grow: 0;
  }

  .forgot-link {
    position: static;
    width: 279px;
    height: 19px;
    left: 24px;
    top: 225px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    color: #195174;
    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 0;
  }

  .form-separator {
    border-top: 1px solid #E7EAEE;
    width: 100%;
    min-height: 1px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-separator .middle-text {
    position: relative;
    background: #FFF;
    padding-left: 15px;
    padding-right: 15px;
  }

  .text-grey {
    color: #9FADBD;
  }

  .soclite {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .soc-google {
    width: 16px;
  }

  .soc-fb {
    width: 16px;
    height: 15px;
  }

  .register-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px;
    position: relative;
    width: 100%;
    margin: auto;
    top: 60px;
    z-index: 11;
  }

  .registered-at {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin: auto;
    top: 35px;
    z-index: 11;
    color: #fff;
    font-weight: bold;
  }

  .register-link span {
    margin-right: 10px;
    color: rgba(255, 255, 255, 0.69);
  }

  .register-link a {
    color: #fff;
    font-weight: bold;
  }

  .kominfo {
    width: 30px;
    margin-left: 10px;
  }

  .checkbox-custom {
    display: flex;
    font-size: 14px;
  }

  .checkbox-custom input {
    margin-right: 10px;
  }
}

