.notifications-panel{
    .notifications{
        .notification{
            padding: 15px;
            margin-bottom: 15px;
            .icon{
                display: flex;
                align-items: center;
                [class^="icon-"]{
                    font-size: 20px;
                    display: inline-block;
                    width: 25px;
                }
            }
            &.new{
                background: rgba(46, 194, 149, 0.2);
            }
        }
    }
}

.member-report{
    .chart{
        min-height: 340px;
    }
}
.badge{
    padding: 6px;
    font-size: 10px;
    font-weight: $font-style-regular;
    border-radius: 12px;
    &.red{
        background-color: $red;
        color: $white;
    }
    &.green{
        background-color: $green4;
        color: $white;
    }
}

.member-info-panel{
    .avatar-info{
        display: flex;
        .avatar{
            margin-right: 15px;
            img{
                width: 80px;
                height: 80px;
                object-fit: cover;
                border-radius: 50%;
            }
        }
    }

    .menu-panel{
        .list-group-item{
            display: flex;
            color: $black;
            justify-content: space-between;
            transition: all 0.4s;
            border-radius: 0;
            padding-left: 0;
            align-items: center;
            padding-right: 0;
            &:last-child{
                padding-top: 3rem;
            }
            &:hover{
                color: $brand-color;
                border-color: $brand-color;
                .icon-chevron-right{
                    &:before{
                        color: $brand-color;
                    }
                }
            }
            .left{
                display: flex;
                align-items: center;
                [class^='icon-']{
                    font-size: 24px;
                    margin-right: 0.5rem;
                }
            }

            .right{
                display: flex;
                align-items: center;
                [class^='icon-']{
                    font-size: 18px;
                    display: inline-block;
                    margin-left: 0.5rem;
                }
                .icon-chevron-right{
                    font-size: 10px;
                }
            }
        }
    }
}

.ticket-page{
    .ticket-list{
        margin-top: 100px;
        .ticket{
            margin-left: 16px;
            margin-right: 16px;
            margin-bottom: 16px;
            .row{
                margin-bottom: 10px;
                align-items: center;
                .label{
                    font-size: 14px;
                    font-weight: $font-style-bold;
                }
                .value{
                    font-weight: $font-style-regular;
                    font-size: 14px;

                }

                .ribbon{
                    font-size: 12px;
                    padding: 5px;
                }
            }
        }
    }
}

.millionaire-card{
    position: relative;
    .background{
        img{
            width: 100%;
            height: auto;
        }
        z-index: $z-index-form;
        position: relative;
    }

    .card-content{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $z-index-form + 1;
        padding: 15px;

        .upper-content{
            display:flex;
            justify-content: space-between;
            align-items: center;
        }
        .saldo-label{
            font-size: 18px;
            line-height: 22px;
            color: $white;
            font-weight: $font-style-regular;
        }
        .saldo{
            color: $white;
            line-height: 33px;
            font-size: 24px;
        }

        .actions-button{
            display:flex;
            position: absolute;
            bottom: 15px;
            left: 15px;
            .secondary-button{
                border:none;
            }
        }

        @include media-breakpoint-up(md){
            padding: 30px 15px 30px 25px;

            .saldo{
                line-height: 65px;
                font-size: 48px;
            }

            .actions-button{
                bottom: 25px;
                left: 25px;
            }
        }
    }
}

.millionaire-verified-card{
    position: relative;

    .background{
        margin-top: 30px;
        margin-bottom: 30px;
        img{
            width: 100%;
            height: auto;
            border-radius: 12px;
            &.gold{
                filter: drop-shadow(0px 12px 12px rgba(251, 131, 94, 0.25));
            }
            &.platinum{
                filter: drop-shadow(0px 12px 12px rgba(0, 0, 0, 0.25));
            }
            &.silver{
                filter: drop-shadow(0px 12px 12px #E7EAEE);
            }
        }
        position: relative;
        z-index: $z-index-form;
    }

    .card-content{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $z-index-form + 1;
        padding: 30px;

        .logo{
            width: 60%;
            @include media-breakpoint-down(sm){
                width: 40%;
            }
        }

        .upper-content{
            display:flex;
            justify-content: space-between;
            align-items: center;
        }
        .saldo-label{
            font-size: 18px;
            line-height: 22px;
            color: $white;
            font-weight: $font-style-regular;
        }
        .saldo{
            color: $white;
            line-height: 33px;
            font-size: 24px;
        }
        .card-owner{
            position: absolute;
            bottom: 50px;
            left: 40px;
            .id{
                font-weight: 400;
                font-size: 12px;
                font-family: $font-family-chivo;
                line-height: 14px;
                letter-spacing: 0.5em;
                color: $white;
            }

            .name{
                font-size: 18px;
                font-weight: 700;
                font-family: $font-family-playfair;
                line-height: 24px;
                color: $white;
            }
        }

        @include media-breakpoint-up(md){
            padding: 30px 15px 0 35px;
            .logo{
                width: 40%;
            }
            .card-owner{
                .id{
                    font-size: 1.2em;
                    line-height: 1;
                    margin-left: 3px;
                }

                .name{
                    font-size: 3em;
                    line-height: 1;
                    margin-bottom: 15px;
                }
            }
        }
    }
}

.transactions{
    padding-top: 10px;
    padding-bottom: 10px;
    .transaction{
        .card{
            padding: 15px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}

.about-team-each {
  height: 270px;
  h6 {
    font-size: 12px;
  }
  a {
    font-size: 12px;
  }
  span {
    font-size: 12px;
  }
  @include media-breakpoint-up(md){
    height: 450px;
  }
}
