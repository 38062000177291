.member-points-well{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon-poin{
        flex-grow: 0;
        font-size: 40px;
    }

    .points{
        flex-grow: 1;
        padding-left: 20px;
        padding-top: 5px;
    }

    .history-button{
        background: none;
        border: none;
        .icon-history{
            font-size: 40px;
        }
    }
}

.redeem-history{
    padding-top: 1.5rem;
    .card{
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}