.has-back-button{
    .main-content{
        padding-bottom: 0;
        &.full-screen{
            padding-bottom: 0;
            &.with-fixed-bottom-button{
                padding-bottom: 120px;
            }
        }
    }
}

.main-content{
    margin: auto;
    

    &.bg-black {
        padding-bottom: 56px;
        background: $light-grey;
    }

    &.full-screen{
        margin-left: -10px;
        margin-right: -10px;
        padding-top: 0;
        padding-bottom: 56px;
        min-height: 100vh;
        .background{
            position: fixed;
            top: 45%;
            transform: translateY(-50%);
            text-align: center;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: $max-width-wrapper;

            padding-left: 16px;
            padding-right: 16px;

            [class^="icon-"]{
                font-size: 50px;
            }

            img{
                max-width: 80%;

            }
            @include media-breakpoint-up(md){
                padding-left: 50px;
                padding-right: 50px;

                img{
                    max-width: 50%;
                }
            }
        }
    }

    &.with-fixed-bottom-button{
        padding-bottom: 120px;

        .footer-buttons{
            display: flex;
            height: 85px;
            background: $white;
            position: fixed;
            bottom: 0;
            z-index: $z-index-fixed-button;
            width: 100%;
            max-width: $max-width-wrapper;
            padding-left: 16px;
            padding-right: 16px;
            align-items: center;
            left: 50%;
            transform: translateX(-50%);
            @include ramerame-box-shadow();
            button, a{
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }

    &.no-padding{
        padding: 0;
    }
}

.main-body.full{
    max-width: $max-width-wrapper;
    margin: auto;
    padding-left: 0;
    padding-right: 0;
    .main-content.full-screen{
        padding-bottom: 0;
    }
}
