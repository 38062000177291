.registration-modal{
    .full-height-page{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: $white;
        padding-left: 15px;
        padding-right: 15px;
        background: linear-gradient(180deg, #195174 0%, #009D9F 100%);
        position: relative;
        .top-logo{
            position: absolute;
            top: 15%;
            left: 50%;
            transform: translateX(-50%);

            img{
                width: 100%;
            }
        }
    }

    &.welcome{
        .full-height-page{
            background: $white;
            color: $black;
        }
    }
}
